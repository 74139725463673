import React from 'react'
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import { Link } from 'gatsby'

export default () => (

  <Layout>
    <Helmet>
      <title>How to use Joycards - step 1</title>
      <meta name="description" content="Start free and then record the first video for your group video greeting card" />
    </Helmet>
    <section className='ht-wrapper'>
      <div className='container'>
        <div className='ht-screen flex flex-col flex-jc'>
          <div className='ht-counter'>
            <span>1 of 3</span>
          </div>
          <div className='ht-body flex flex-ac'>
            <div>
              <span className='heading-span'>How to</span>
              <h1>Step 1:</h1>
              <p class="how-text">Create a free account & then record your video.</p>
            </div>
          </div>
          <div className='ht-foot'>
            <Link to="/how-to-2" className='btn btn-teal btn-lg cta-btn'>Go to step 2</Link>
            <div className='skip-link'>
              <a href="https://app.joy.cards/accounts/signup/" target="_blank" rel="noopener noreferrer">Skip to sign up</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
